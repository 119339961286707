import { createInternalLink } from "~utils/link_utils/createInternalLink";

import type { ResolvedRelationData } from ".";
import type { StoryblokEntry } from "~types/storyblok.types";

/**
 * In the visual editor, relations arrive as an object of storyblok entries,
 * directly from the storyblok API
 */
export function transformStoryblokEntryApiToCarouselItem(
  storyblokEntry: StoryblokEntry
): ResolvedRelationData {
  const { content: entryContent, full_slug } = storyblokEntry || {};
  const { brandIcon, linkToExternalPage, pageMetadata, title, description } =
    entryContent || {};

  const { ctaText, description: metaDescription, prefix } = pageMetadata || {};
  const link = createInternalLink(full_slug);

  return {
    brandIcon,
    title: title || prefix,
    ctaText,
    summary: description || metaDescription,
    link: linkToExternalPage || link,
    full_slug,
  };
}
