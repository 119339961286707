import React from "react";

import { Box } from "~components/Box";

import { AnimLayerAlert } from "~animations/__layers__/mock_ui_components/AnimLayerAlert";
import { AnimLayerNotification } from "~animations/__layers__/mock_ui_components/AnimLayerNotification";
import {
  alertStyle,
  notificationStyle,
} from "~animations/products_addons/_AnimatedHeroWrapper/styles.css";

import type { AccordionAnimationsHomepageProps } from "./AccordionAnimationsHomepage";
import type { BoxProps } from "~components/Box";

export interface FloatingUiElementsProps extends BoxProps {
  items: AccordionAnimationsHomepageProps["items"];
}

export function FloatingUiElements({ items }: FloatingUiElementsProps) {
  if (!Array.isArray(items)) return null;

  return (
    <>
      {items.map((item, index) => {
        const {
          animLayerNotification: animLayerNotificationBlokArray,
          animLayerAlert: animLayerBlokArray,
        } = item || {};

        const [animLayerNotification] = animLayerNotificationBlokArray || [];
        const [animLayerAlert] = animLayerBlokArray || [];

        if (!animLayerNotification && !animLayerAlert) return null;

        return (
          <Box
            key={animLayerAlert?._uid || animLayerNotification?._uid}
            data-motion-group={index}
            data-test="findMe"
          >
            {animLayerNotification && (
              <AnimLayerNotification
                boxShadow="light"
                className={notificationStyle}
                {...animLayerNotification}
              />
            )}
            {animLayerAlert && (
              <AnimLayerAlert
                boxShadow="light"
                className={alertStyle}
                {...animLayerAlert}
              />
            )}
          </Box>
        );
      })}
    </>
  );
}
