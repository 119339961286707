import { useContext, useEffect, useState } from "react";

import { CarouselContext } from "pure-react-carousel";

export const useCarouselCurrentSlide = (): number => {
  const carouselContext = useContext(CarouselContext);

  /** Will need to explicitly listen to and update state for slide changes */
  const [currentSlide, setCarouselState] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    /** Handle changes in carousel state current slide */
    const onChange = () => {
      setCarouselState(carouselContext.state.currentSlide);
    };

    /** Subscribe to context changes */
    carouselContext.subscribe(onChange);

    /** Clean up subscribe on unmount */
    return () => {
      return carouselContext.unsubscribe(onChange);
    };
  }, [carouselContext]);

  return currentSlide || 0;
};
