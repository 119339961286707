import React, { useContext, useEffect, useState } from "react";

import { CarouselContext, Dot } from "pure-react-carousel";

import { Box } from "~components/Box";
import { SvgLogo } from "~components/SvgLogo";

import * as styles from "./_CarouselQuoteLogoButtons.styles.css";

import type { CarouselQuotesProps } from ".";
import type { BoxProps } from "~components/Box";

export function CarouselQuoteLogoButtons({
  quotes,
  ...rest
}: Pick<CarouselQuotesProps, "quotes"> & BoxProps) {
  /** Access parent carousel's state from context */
  const carouselContext = useContext(CarouselContext);

  /** Will need to explicitly listen to and update state for slide changes */
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide
  );

  useEffect(() => {
    /** Handle changes in carousel state current slide */
    const onChange = () => {
      setCurrentSlide(carouselContext.state.currentSlide);
    };

    /** Subscribe to context changes */
    carouselContext.subscribe(onChange);

    /** Clean up subscribe on unmount */
    return () => {
      return carouselContext.unsubscribe(onChange);
    };
  }, [carouselContext]);

  return (
    <Box {...rest}>
      {quotes.map((child, index) => {
        const { logo } = child || {};
        if (!logo) return null;

        return (
          <Dot
            slide={index}
            /** Data is static, can key by index */
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${logo}`}
            className={styles.button}
            data-active={index === currentSlide}
          >
            <SvgLogo
              height="spacing8"
              svgLogo={logo}
              color={
                index === currentSlide
                  ? "text_highContrast"
                  : "text_lowContrast"
              }
            />
          </Dot>
        );
      })}
    </Box>
  );
}
