import * as React from "react";

import { commonStackedGridCell } from "~styles/common/common.stacked-grid.css";
import { getSprinkles } from "~styles/getSprinkles.css";

import { AccordionRichText } from "~components/AccordionRichText";
import { Box } from "~components/Box";
// import { ImageBackground } from "~components/ImageBackground";
import { StoryblokEditable } from "~components/StoryblokEditable";
import { Video } from "~components/Video";

import { AnimatedMaskTitle } from "~animations/AnimatedMaskTitle";
import { AnimationSlider } from "~animations/animation_primitives/AnimationSlider";

import {
  SequencedAnimationsContext,
  SequencedAnimationsContextProvider,
} from "~context/SequencedAnimationsContext";

import { ANIMATIONS } from "../ACCORDION_ANIMATIONS_MAP";
import { FloatingUiElements } from "../AccordionFloatingUiElements";
import {
  accordionAnimationsProductPageWrapper,
  styleAccordionAsCard,
  videoWrapper,
} from "./styles.css";

import type { AnimLayerAlertProps } from "~animations/__layers__/mock_ui_components/AnimLayerAlert";
import type { AnimLayerNotificationProps } from "~animations/__layers__/mock_ui_components/AnimLayerNotification";
import type { BlokWithAnimation } from "~animations/animation_primitives/AnimationSlider";
import type { AccordionRichTextItemProps } from "~components/AccordionRichText/_AccordionRichTextItem";
import type { VideoProps } from "~components/Video";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { varsClipPathInset } from "~styles/vars/varsClipPathInset.css";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AccordionAnimationsProductPageItemProps
  extends BlokWithAnimation,
    Omit<AccordionRichTextItemProps, "index"> {
  animLayerNotification?: Array<StoryblokBlok & AnimLayerNotificationProps>;
  animLayerAlert?: Array<StoryblokBlok & AnimLayerAlertProps>;
  mask?: keyof typeof varsClipPathInset;
}

export type AccordionAnimationsProductPageProps = StoryblokBlok & {
  items: Array<Omit<AccordionAnimationsProductPageItemProps, "index">>;
  title?: string;
  shouldShowNumbers?: boolean;
  video?: Array<StoryblokBlok & VideoProps>;
};

const GRID_TEMPLATE_COLUMNS: GetSprinklesArgs["gridTemplateColumns"] = {
  mobile: 1,
  desktop: 2,
};

const ANIMATION_DISPLAY_PROPS = { mobile: "none", desktop: "block" } as const;

export function AccordionAnimationsProductPage({
  items,
  title,
  shouldShowNumbers,
  video: videoBlokArray,
  ...rest
}: AccordionAnimationsProductPageProps) {
  const [videoBlok] = videoBlokArray || [];

  return (
    <SequencedAnimationsContextProvider length={items?.length}>
      <SequencedAnimationsContext.Consumer>
        {({ current, handleUserSetState }) => {
          return (
            <StoryblokEditable {...rest}>
              <Box marginY="sectionRowGap" overflow="hidden">
                <Box className={accordionAnimationsProductPageWrapper}>
                  {videoBlok && (
                    <Video
                      {...videoBlok}
                      hasBorderRadius
                      videoClassName={getSprinkles({
                        height: "100%",
                        width: "auto",
                      })}
                      className={videoWrapper}
                    />
                  )}
                  {/* {backgroundImage && (
                    <ImageBackground
                      isolation="isolate"
                      image={backgroundImage}
                      borderRadius="md"
                    />
                  )} */}
                  {title && (
                    <AnimatedMaskTitle
                      text={title}
                      marginBottom="headerHeight"
                    />
                  )}
                  <Box
                    display="grid"
                    gap="gutterWidth"
                    gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
                    alignItems="center"
                    position="relative"
                  >
                    <AccordionRichText
                      value={current?.toString()}
                      onValueChange={handleUserSetState}
                      accordionItems={items}
                      collapsible={false}
                      shouldShowNumbers={shouldShowNumbers}
                      className={styleAccordionAsCard}
                    />
                    {/**
                     * ------------------------------------------------------------------------------
                     * Wrapper for masked video and floating UI elements (which are positioned over mask
                     * with absolute positioning)
                     * ------------------------------------------------------------------------------
                     */}
                    <Box
                      display={ANIMATION_DISPLAY_PROPS}
                      width="gridSpan5"
                      marginLeft="auto"
                      position="relative"
                      aspectRatio="square"
                    >
                      <AnimationSlider
                        animationComponentMap={ANIMATIONS}
                        className={commonStackedGridCell}
                        items={items}
                      />
                      <FloatingUiElements items={items} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </StoryblokEditable>
          );
        }}
      </SequencedAnimationsContext.Consumer>
    </SequencedAnimationsContextProvider>
  );
}
