import React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import { CarouselProvider as PureReactCarouselProvider } from "pure-react-carousel";

import { getSprinkles } from "~styles/getSprinkles.css";

import type { CarouselProviderProps as PureReactCarouselProviderProps } from "pure-react-carousel";
import type { ReactNode } from "react";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

interface CarouselProviderProps
  extends Omit<
      PureReactCarouselProviderProps,
      "naturalSlideHeight" | "naturalSlideWidth"
    >,
    GetSprinklesArgs {
  children: ReactNode;
  naturalSlideWidth?: number;
  naturalSlideHeight?: number;
}

export function CarouselProvider({
  children,
  totalSlides = 0,
  naturalSlideWidth = 0,
  naturalSlideHeight = 0,
  isIntrinsicHeight = true,
  ...rest
}: CarouselProviderProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  return (
    <PureReactCarouselProvider
      naturalSlideWidth={naturalSlideWidth}
      naturalSlideHeight={naturalSlideHeight}
      isIntrinsicHeight={isIntrinsicHeight}
      className={getSprinkles({
        ...atomProps,
      })}
      lockOnWindowScroll
      totalSlides={totalSlides}
      {...otherProps}
    >
      {children}
    </PureReactCarouselProvider>
  );
}
