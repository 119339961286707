import { AnimIdentityDetails } from "~animations/AnimIdentityDetails";
import { AnimationIllustrationAllAccounts } from "~animations/AnimationIllustrationAllAccounts";
import { AnimLayerInfoDialog } from "~animations/__layers__/mock_ui_components/AnimLayerInfoDialog";
import { AnimationIllustrationDeposit } from "~animations/in_body_illustrations/AnimationIllustrationDeposit";
import { AnimationIllustrationSuccessDeposit } from "~animations/in_body_illustrations/AnimationIllustrationDepositSuccess";
import { AnimationIllustrationIGamingWithdrawal } from "~animations/in_body_illustrations/AnimationIllustrationIGamingWithdrawal";
import { AnimationIllustrationPaymentRate } from "~animations/in_body_illustrations/AnimationIllustrationPaymentRate";
import { AnimationIllustrationSignupRate } from "~animations/in_body_illustrations/AnimationIllustrationSignupRate";
import { AnimationIllustrationTravelRefund } from "~animations/in_body_illustrations/AnimationIllustrationTravelRefund";
import { AnimationIllustrationWithdrawFs } from "~animations/in_body_illustrations/AnimationIllustrationWithdrawFs";
import { AnimationPaymentsList } from "~animations/in_body_illustrations/AnimationPaymentsList";
import { AnimationTotalSpendCategories } from "~animations/in_body_illustrations/AnimationTotalSpendCategories";
import { AnimIndustryCrypto } from "~animations/industries/AnimIndustryCrypto";
import { AnimIndustryEcommerce } from "~animations/industries/AnimIndustryEcommerce";
import { AnimIndustryFinancialServices } from "~animations/industries/AnimIndustryFinancialServices";
import { AnimIndustryIGaming } from "~animations/industries/AnimIndustryIGaming";
import { AnimIndustryTravel } from "~animations/industries/AnimIndustryTravel";
import { AnimHeroData } from "~animations/products_addons/AnimHeroData";
import { AnimHeroPayments } from "~animations/products_addons/AnimHeroPayments";
import { AnimHeroPayouts } from "~animations/products_addons/AnimHeroPayouts";
import { AnimHeroSignup } from "~animations/products_addons/AnimHeroSignup";
import { AnimHeroVerification } from "~animations/products_addons/AnimHeroVerification";
import { AnimHeroVrp } from "~animations/products_addons/AnimHeroVrp";

import type { ComponentMap } from "~types/global.types";

export const ANIMATIONS: ComponentMap = {
  AnimHeroPayments,
  AnimHeroData,
  AnimHeroVrp,
  AnimHeroPayouts,
  AnimHeroVerification,
  AnimHeroSignup,
  AnimationTotalSpendCategories,
  AnimationPaymentsList,
  AnimationIllustrationDeposit,
  AnimationIllustrationSuccessDeposit,
  AnimLayerInfoDialog,
  AnimationIllustrationPaymentRate,
  AnimationIllustrationSignupRate,
  AnimationIllustrationTravelRefund,
  AnimationIllustrationWithdrawFs,
  AnimIndustryEcommerce,
  AnimIndustryFinancialServices,
  AnimIndustryCrypto,
  AnimIndustryTravel,
  AnimIndustryIGaming,
  AnimIdentityDetails,
  AnimationIllustrationAllAccounts,
  AnimationIllustrationIGamingWithdrawal,
} as const;
