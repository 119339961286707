import React from "react";

import clsx from "clsx";

import { Box } from "~components/Box";

import { LayoutSection } from "~sections/LayoutSection";
import { removeTopBorderClassName } from "~sections/LayoutSection/styles.css";

import * as styles from "./styles.css";
import { getLayoutWithSidebarStyles } from "./styles.css";

import type { ReactNode } from "react";
import type { BoxProps } from "~components/Box";
import type { LayoutSectionProps } from "~sections/LayoutSection";

/**
 * ------------------------------------------------------------------------------
 * Primary content
 * ------------------------------------------------------------------------------
 */

interface PrimaryProps extends BoxProps {
  children: ReactNode;
}

function Primary({ children, ...rest }: PrimaryProps) {
  return (
    <Box className={styles.layoutSidebarPrimary} {...rest}>
      {children}
    </Box>
  );
}

/**
 * ------------------------------------------------------------------------------
 * Secondary (sidebar) content
 * ------------------------------------------------------------------------------
 */

export interface SecondaryProps extends BoxProps {
  isSidebarSticky?: boolean;
}

function Secondary({
  children,
  isSidebarSticky,
  maxWidth,

  ...rest
}: SecondaryProps) {
  return (
    <Box {...rest} as="aside" minWidth="gridSpan3">
      <Box
        className={styles.getSidebarStyles({ isSticky: isSidebarSticky })}
        maxWidth={maxWidth || "gridSpan3"}
      >
        {children}
      </Box>
    </Box>
  );
}

/**
 * ------------------------------------------------------------------------------
 * Sidebar section
 * ------------------------------------------------------------------------------
 */

interface SectionProps extends BoxProps {
  title: string;
  children: ReactNode;
}

function Section({ title, children, ...rest }: SectionProps) {
  return (
    <Box marginBottom="spacing6" {...rest}>
      <Box display="flex" alignItems="center" gap="spacing1">
        <Box marginBottom="spacing1" textAppearance="prefix_md">
          {title}
        </Box>
      </Box>

      {children}
    </Box>
  );
}

/**
 * ------------------------------------------------------------------------------
 * Outer wrapper component
 * ------------------------------------------------------------------------------
 */

interface WrapperProps extends LayoutSectionProps {
  sidebarPositionDesktop: "left" | "right";
  sidebarPositionMobile: "top" | "bottom";
  gapSizeDesktop?: "sm" | "md" | "lg";
  removeTopBorder?: boolean;
}
/**
 * Wraps layout with sidebar. Accepts only {@link: PrimaryProps} or {@link: SecondaryProps}
 */
function Wrapper({
  sidebarPositionDesktop,
  sidebarPositionMobile,
  gapSizeDesktop = "lg",
  children,
  className,
  removeTopBorder,
  ...rest
}: WrapperProps) {
  return (
    <LayoutSection
      className={removeTopBorder ? removeTopBorderClassName : undefined}
      classNameInner={clsx(
        getLayoutWithSidebarStyles({
          sidebarPositionDesktop,
          sidebarPositionMobile,
          gapSizeDesktop,
        }),
        className
      )}
      {...rest}
    >
      {children}
    </LayoutSection>
  );
}

export const LayoutSidebar = {
  Wrapper,
  Primary,
  Section,
  Secondary,
};
