import React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import { Slide, Slider } from "pure-react-carousel";

import {
  carouselFade_hide,
  carouselFade_show,
} from "~styles/common/animation.carousel.css";
import { getSprinkles } from "~styles/getSprinkles.css";

import { Box } from "~components/Box";
import { Quote } from "~components/Quote";
import { QuoteIconFloating } from "~components/Quote/QuoteIconFloating";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { CarouselProvider } from "../CarouselProvider";
import { CarouselQuoteLogoButtons } from "./_CarouselQuoteLogoButtons";
import { getCarouselQuoteLogoButtonsStyle } from "./styles.css";

import type { QuoteProps } from "~components/Quote";

export interface CarouselQuotesProps {
  quotes: Array<QuoteProps>;
}

export function CarouselQuotes({ quotes, ...rest }: CarouselQuotesProps) {
  const { atomProps } = extractAtomsFromProps(rest, getSprinkles);

  if (!Array.isArray(quotes)) return null;

  const isScrollableOnMobile = quotes.length > 3;
  const isScrollableOnTablet = quotes.length > 4;

  return (
    <StoryblokEditable {...rest}>
      <Box maxWidth="gridSpan8" marginX="auto" {...rest}>
        <CarouselProvider
          infinite
          lockOnWindowScroll
          totalSlides={quotes.length}
          className={getSprinkles({
            ...atomProps,
            marginX: "auto",
          })}
        >
          <CarouselQuoteLogoButtons
            display="flex"
            gap="spacing5"
            alignItems="center"
            borderBottom="default"
            marginBottom="spacing3"
            overflow="auto"
            quotes={quotes}
            className={getCarouselQuoteLogoButtonsStyle({
              isScrollableOnMobile,
              isScrollableOnTablet,
            })}
          />
          <Box position="relative">
            <QuoteIconFloating />
            <Slider
            // classNameAnimation={animation_carouselFade}
            >
              {quotes.map((quote, index) => {
                return (
                  <Slide
                    classNameHidden={carouselFade_hide}
                    classNameVisible={carouselFade_show}
                    index={index}
                    key={quote._uid}
                  >
                    <Quote marginY="none" isIconVisible={false} {...quote} />
                  </Slide>
                );
              })}
            </Slider>
          </Box>
        </CarouselProvider>
      </Box>
    </StoryblokEditable>
  );
}
