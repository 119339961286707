import React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";
import { Slider } from "pure-react-carousel";

// import  { animation_carouselSlider } from "~styles/common/animation.carousel.css";
import { getSprinkles } from "~styles/getSprinkles.css";

import { useCarouselCurrentSlide } from "../CarouselProvider/useCarouselCurrentSlide";
import { RelationsCarouselSlide } from "./_RelationsCarouselSlide";

import type { ResolvedCarouselItemShape } from ".";
import type { SliderProps } from "pure-react-carousel";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { ComponentMap } from "~types/global.types";

interface RelationsCarouselSliderProps
  extends GetSprinklesArgs,
    Omit<SliderProps, "children" | "color"> {
  items: Array<ResolvedCarouselItemShape>;
  animationComponentMap: ComponentMap;
}

export function RelationsCarouselSlider({
  items,
  className: userClassName,
  animationComponentMap,
  ...rest
}: RelationsCarouselSliderProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  const currentSlide = useCarouselCurrentSlide();

  return (
    <Slider
      {...otherProps}
      // classNameAnimation={animation_carouselSlider}
      className={clsx(
        userClassName,
        getSprinkles({
          overflow: "visible",
          maxWidth: "gridSpan10",
          marginX: "auto",
          ...atomProps,
        })
      )}
    >
      {Array.isArray(items) &&
        items.map((item, index) => {
          return (
            <RelationsCarouselSlide
              // eslint-disable-next-line react/no-array-index-key
              key={`${item.title}-${index}`}
              animation={item.animation}
              animationComponentMap={animationComponentMap}
              ctaText={item.ctaText}
              currentSlide={currentSlide}
              index={index}
              link={item.link}
              summary={item.summary}
              title={item.title}
            />
          );
        })}
    </Slider>
  );
}
