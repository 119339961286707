import * as React from "react";

import { faArrowLeftLong } from "@fortawesome/sharp-regular-svg-icons/faArrowLeftLong";
import { faArrowRightLong } from "@fortawesome/sharp-regular-svg-icons/faArrowRightLong";
import { ButtonBack, ButtonNext } from "pure-react-carousel";

import { getSprinkles } from "~styles/getSprinkles.css";

import { Box } from "~components/Box";
import { getButtonStyles } from "~components/Button/styles.css";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimIndustryCrypto } from "~animations/industries/AnimIndustryCrypto";
import { AnimIndustryEcommerce } from "~animations/industries/AnimIndustryEcommerce";
import { AnimIndustryIGaming } from "~animations/industries/AnimIndustryIGaming";
import { AnimIndustryTravel } from "~animations/industries/AnimIndustryTravel";
import { AnimMockUiAccountAggregation } from "~animations/mock_ui/AnimMockUiAccountAggregation";
import { AnimMockUiAccountOverview } from "~animations/mock_ui/AnimMockUiAccountOverview";
import { AnimMockUiAuthentication } from "~animations/mock_ui/AnimMockUiAuthentication";
import { AnimMockUiBankSelection } from "~animations/mock_ui/AnimMockUiBankSelection";
import { AnimMockUiConsent } from "~animations/mock_ui/AnimMockUiConsent";
import { AnimMockUiDeposit } from "~animations/mock_ui/AnimMockUiDeposit";
import { AnimMockUiEcommerce1 } from "~animations/mock_ui/AnimMockUiEcommerce1";
import { AnimMockUiEcommerce2 } from "~animations/mock_ui/AnimMockUiEcommerce2";
import { AnimMockUiEcommerceRefund1 } from "~animations/mock_ui/AnimMockUiEcommerceRefund1";
import { AnimMockUiEcommerceRefund2 } from "~animations/mock_ui/AnimMockUiEcommerceRefund2";
import { AnimMockUiLoading } from "~animations/mock_ui/AnimMockUiLoading";
import { AnimMockUiSignup2 } from "~animations/mock_ui/AnimMockUiSignup2";
import { AnimMockUiSuccess } from "~animations/mock_ui/AnimMockUiSuccess";
import { AnimMockUiVerification1 } from "~animations/mock_ui/AnimMockUiVerification1";
import { AnimMockUiVrp1 } from "~animations/mock_ui/AnimMockUiVrp1";
import { AnimMockUiVrp2 } from "~animations/mock_ui/AnimMockUiVrp2";
import { AnimMockUiWithdrawal } from "~animations/mock_ui/AnimMockUiWithdrawal";

import {
  SequencedAnimationsContext,
  SequencedAnimationsContextProvider,
} from "~context/SequencedAnimationsContext";

import { CarouselProvider } from "../CarouselProvider";
import { CarouselMiddleWare } from "./_CarouselMiddleware";
import { RelationsCarouselSlider } from "./_CarouselRelationsSlider";
import { transformStoryblokEntryApiToCarouselItem } from "./_transformStoryblokEntryApiToCarouselItem";
import { transformStoryblokEntryNodeToCarouselItem } from "./_transformStoryblokEntryNodeToCarouselItem";
import { useStoryblokRelationsCarousel } from "./_useStoryblokRelationsCarousel";

import type { IconBrandProps } from "~components/IconBrand";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type {
  StoryblokBlok,
  StoryblokEntry,
  StoryblokFieldLink,
} from "~types/storyblok.types";

/**
 * ------------------------------------------------------------------------------
 * Map of animations configurable in Storyblok
 * ------------------------------------------------------------------------------
 */

export const ANIMATIONS = {
  AnimMockUiEcommerce1,
  AnimMockUiEcommerce2,
  AnimMockUiBankSelection,
  AnimMockUiAuthentication,
  AnimMockUiSuccess,
  AnimMockUiEcommerceRefund1,
  AnimMockUiAccountAggregation,
  AnimMockUiEcommerceRefund2,
  AnimMockUiAccountOverview,
  AnimMockUiWithdrawal,
  AnimMockUiLoading,
  AnimMockUiVrp1,
  AnimMockUiVrp2,
  AnimMockUiConsent,
  AnimMockUiDeposit,
  AnimMockUiSignup2,
  AnimMockUiVerification1,
  AnimIndustryIGaming,
  AnimIndustryCrypto,
  AnimIndustryTravel,
  AnimIndustryEcommerce,
} as const;

export interface ResolvedRelationData extends StoryblokEntry {
  id?: string;
  brandIcon?: IconBrandProps["icon"];
  link?: StoryblokFieldLink;
  ctaText?: string;
  summary?: string;
  title?: string;
}

interface RawCarouselItemShape {
  content: string | (StoryblokEntry & ResolvedRelationData);
  animation?: Array<StoryblokEntry>;
}

export interface ResolvedCarouselItemShape extends ResolvedRelationData {
  animation?: Array<StoryblokEntry>;
}

export interface RelationsCarouselProps extends StoryblokBlok {
  items?: Array<RawCarouselItemShape>;
}

const BUTTON_CLASSNAMES = getButtonStyles({
  appearance: "secondary",
  size: "lg_square",
});

const BUTTON_WRAPPER_PROPS: GetSprinklesArgs = {
  display: {
    mobile: "none",
    desktop: "flex",
  },
  alignItems: "center",
  justifyContent: "center",
  width: "gridSpan2",
  position: "absolute",
  top: "0",
  bottom: "0",
  height: "100%",
  zIndex: "1",
};

export function RelationsCarousel({ items, ...rest }: RelationsCarouselProps) {
  const resolvedItems: Array<ResolvedCarouselItemShape> | null =
    useStoryblokRelationsCarousel({
      items,
      transformApiEntry: transformStoryblokEntryApiToCarouselItem,
      transformGraphQlNode: transformStoryblokEntryNodeToCarouselItem,
      isAlphabetized: false,
      storyblokRelationsNodeType: "CarouselRelationsItemNodes",
    });

  if (!resolvedItems) return null;

  return (
    <SequencedAnimationsContextProvider
      length={resolvedItems?.length}
      orientation="horizontal"
    >
      <SequencedAnimationsContext.Consumer>
        {({ current, handleUserSetState }) => {
          return (
            <CarouselProvider
              infinite
              lockOnWindowScroll
              totalSlides={resolvedItems?.length || 0}
              currentSlide={current}
              className={getSprinkles({ position: "relative" })}
            >
              <CarouselMiddleWare callbackOnSlideChange={handleUserSetState}>
                <StoryblokEditable {...rest}>
                  <Box position="relative">
                    <Box {...BUTTON_WRAPPER_PROPS} left="0">
                      <ButtonBack className={BUTTON_CLASSNAMES}>
                        <IconFontAwesome icon={faArrowLeftLong} />
                      </ButtonBack>
                    </Box>
                    <RelationsCarouselSlider
                      paddingBottom="spacing5"
                      items={resolvedItems}
                      animationComponentMap={ANIMATIONS}
                    />
                    <Box {...BUTTON_WRAPPER_PROPS} right="0">
                      <ButtonNext className={BUTTON_CLASSNAMES}>
                        <IconFontAwesome icon={faArrowRightLong} />
                      </ButtonNext>
                    </Box>
                  </Box>
                </StoryblokEditable>
              </CarouselMiddleWare>
            </CarouselProvider>
          );
        }}
      </SequencedAnimationsContext.Consumer>
    </SequencedAnimationsContextProvider>
  );
}
