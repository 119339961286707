import React, { Suspense } from "react";

import { lazily } from "react-lazily";

import type { CarouselQuotesProps } from ".";

function LazyCarouselQuotes(props: CarouselQuotesProps) {
  const { CarouselQuotes } = lazily(() => {
    return import(".");
  });

  return (
    <Suspense fallback={null}>
      <CarouselQuotes {...props} />
    </Suspense>
  );
}

export default LazyCarouselQuotes;
