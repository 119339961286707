import { useEffect } from "react";

import { useCarouselCurrentSlide } from "../CarouselProvider/useCarouselCurrentSlide";

import type { SequencedAnimationsContextContextProvided } from "~context/SequencedAnimationsContext";

interface CarouselMiddleWareArgs {
  children: JSX.Element;
  callbackOnSlideChange: SequencedAnimationsContextContextProvided["handleUserSetState"];
}

export function CarouselMiddleWare({
  children,
  callbackOnSlideChange,
}: CarouselMiddleWareArgs) {
  const currentSlide = useCarouselCurrentSlide();
  useEffect(() => {
    if (callbackOnSlideChange && typeof currentSlide === "number") {
      callbackOnSlideChange(`${currentSlide}`);
    }
  }, [callbackOnSlideChange, currentSlide]);

  if (!children) return null;

  return children;
}
