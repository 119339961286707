import React, { useEffect } from "react";

import { animate } from "motion";

import {
  ANIMATED,
  getMotionSelector,
} from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { Video } from "~components/Video";

import type { AccordionAnimationItemProps } from "~animations/animation_primitives/AccordionAnimationItem";
import type { BoxProps } from "~components/Box";

interface AccordionAnimationsVideoProps extends BoxProps {
  current?: number;
  previous?: number;
  items: Array<AccordionAnimationItemProps>;
}

export function AccordionAnimationsVideo({
  items,
  current = 0,
  className: userClassName,
  ...rest
}: AccordionAnimationsVideoProps) {
  const refContainer = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      typeof window === "undefined" ||
      !Array.isArray(items) ||
      !refContainer.current
    )
      return;

    const animatedElements = refContainer.current.querySelectorAll(
      getMotionSelector(ANIMATED.video)
    );

    animatedElements?.forEach((animatedElement, index) => {
      /** Get mask var, which is set in the CMS using a key of {@link varsClipPathInset}`  */

      if (current === index) {
        animate(
          animatedElement,
          {
            opacity: [0, 1],
          },

          {
            duration: 0.25,
          }
        );
      } else {
        animate(
          animatedElement,
          {
            opacity: [null, 0],
          },
          {
            duration: 0.25,
            delay: 0.25,
          }
        );
      }
    });
  }, [current, items]);

  return (
    <Box
      ref={refContainer}
      className={userClassName}
      width="100%"
      aspectRatio="square"
      {...rest}
    >
      {Array.isArray(items) &&
        items.map((item) => {
          const { video: videoBlokArray } = item || {};
          if (!Array.isArray(videoBlokArray)) return null;

          const [videoBlok] = videoBlokArray || [];

          if (!videoBlok) return null;

          return (
            <Video
              isHiddenOnMobile
              key={videoBlok._uid}
              data-motion={ANIMATED.video}
              position="absolute"
              objectFit="cover"
              inset="0"
              preload="auto"
              {...videoBlok}
            />
          );
        })}
    </Box>
  );
}
