import React from "react";

import { faArrowRightLong } from "@fortawesome/sharp-regular-svg-icons/faArrowRightLong";
import clsx from "clsx";
import { Slide } from "pure-react-carousel";
import slugify from "slugify";

import { getSprinkles } from "~styles/getSprinkles.css";

import { Box } from "~components/Box";
import { ButtonLink } from "~components/ButtonLink";
import { PhoneMockup } from "~components/PhoneMockup";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import * as styles from "./styles.css";
import { relationsCarouselSlideWrapper } from "./styles.css";

import type { SlideProps } from "pure-react-carousel";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { ComponentMap } from "~types/global.types";
import type { StoryblokBlok, StoryblokFieldLink } from "~types/storyblok.types";

interface RelationsCarouselSlideProps
  extends GetSprinklesArgs,
    Omit<SlideProps, "children" | "color"> {
  animation?: Array<StoryblokBlok>;
  animationComponentMap: ComponentMap;
  ctaText?: string;
  currentSlide: number;
  index: number;
  link?: StoryblokFieldLink;
  summary?: string;
  title?: string;
}

export function RelationsCarouselSlide({
  animation: animationBlokArray,
  animationComponentMap,
  ctaText,
  currentSlide,
  index,
  link,
  summary,
  title,
  ...rest
}: RelationsCarouselSlideProps) {
  const isActive = index === currentSlide;

  const titleSlug = title ? slugify(title, { lower: true }) : undefined;

  if (!titleSlug) return null;

  const [animationBlokProps] = animationBlokArray || [];

  /** Remove keys that may be populated in the CMS config, but are not required for this use case */
  // delete animationBlokProps?.animLayerAlert;
  // delete animationBlokProps?.animLayerNotification;

  /** Get the corresponding react component */

  const AnimationComponent =
    (animationBlokProps?.component &&
      animationComponentMap[animationBlokProps.component]) ||
    null;

  return (
    <Slide
      index={index}
      data-motion-group={index}
      className={clsx(
        relationsCarouselSlideWrapper,
        getSprinkles({
          width: "gridSpan12",
        })
      )}
      innerClassName={clsx(
        styles.getRelationsCarouselSlideStyles({ isActive }),
        getSprinkles({
          maxWidth: "gridSpan8",
          marginX: "auto",
          paddingX: "spacing5",
          paddingTop: "spacing5",

          border: "default",
          overflow: "hidden",
          borderBottomRightRadius: "xl",
          borderTopLeftRadius: "xl",
        })
      )}
    >
      <Box
        display="grid"
        alignItems="end"
        height="50vh"
        gridTemplateColumns={{ mobile: 1, desktop: 2 }}
        gap="gutterWidth"
      >
        <StoryblokEditable {...rest}>
          <Box padding="spacing3" marginY="auto">
            <Box
              as="h3"
              className={styles.transitionedText}
              color={isActive ? "text_highContrast" : "text_lowContrast"}
              fontFamily="formula"
              textAppearance="h4"
            >
              {title}
            </Box>
            <Box
              as="p"
              className={styles.transitionedText}
              color={isActive ? "text_highContrast" : "text_lowContrast"}
              textAppearance="body_md"
            >
              {summary}
            </Box>

            {link && (
              <ButtonLink
                appearance="tertiary"
                link={link}
                opacity={isActive ? "1" : "0"}
                className={styles.transitionedText}
                iconLeft={faArrowRightLong}
              >
                {ctaText}
              </ButtonLink>
            )}
          </Box>
          <Box
            width="100%"
            height="100%"
            position="relative"
            display={{
              mobile: "none",
              desktop: "block",
            }}
          >
            {AnimationComponent && (
              <AnimatedElement
                __border="none"
                animationLevel="primary"
                aspectRatio="mobile"
                position="absolute"
                width="100%"
              >
                <AnimationComponent as={PhoneMockup} {...animationBlokProps} />
              </AnimatedElement>
            )}
          </Box>
        </StoryblokEditable>
      </Box>
    </Slide>
  );
}
